import { USD_DECIMALS } from '@yldr/math-utils';
import {
  ApproveDelegationType,
  ApproveType,
  BaseDebtToken,
  ERC1155Service,
  ERC20_2612Service,
  ERC20Service,
  EthereumTransactionTypeExtended,
  FaucetParamsType,
  FaucetService,
  IncentivesController,
  IncentivesControllerV2,
  IncentivesControllerV2Interface,
  MAX_UINT_AMOUNT,
  Pool,
  PoolBaseCurrencyHumanized,
  PoolBundle,
  ReserveDataHumanized,
  ReservesIncentiveDataHumanized,
  UiPoolDataProvider,
  UserERC1155ReserveDataHumanized,
  UserReserveDataHumanized,
  V3FaucetService,
  PositionManagerLeverageWrapper,
  PositionManagerContract,
  YLDRLeverageAutomations,
  RangeConfigStruct,
  EndConfigStruct,
  IALMCreateAndLeverageMintParams,
  ALMCreateAndLeverage,
  ALMLeveragedPosition,
} from '@yldr/contract-helpers';
import {
  LPBorrowParamsType,
  LPSetUsageAsCollateral,
  LPWithdrawParamsType,
} from '@yldr/contract-helpers/dist/esm/v3-pool-contract/lendingPoolTypes';
import {
  LPSignERC20ApprovalType,
  LPSupplyParamsType,
  LPSupplyWithPermitType,
} from '@yldr/contract-helpers/dist/esm/v3-pool-contract/lendingPoolTypes';
import { SignatureLike } from '@ethersproject/bytes';
import dayjs from 'dayjs';
import { BigNumber, PopulatedTransaction, utils } from 'ethers';
import { produce } from 'immer';
import { ClaimRewardsActionsProps } from 'src/components/transactions/ClaimRewards/ClaimRewardsActions';
import { RepayActionProps } from 'src/components/transactions/Repay/RepayActions';
import { Approval } from 'src/helpers/useTransactionHandler';
import { MarketDataType } from 'src/ui-config/marketsConfig';
import { minBaseTokenRemainingByNetwork, optimizedPath } from 'src/utils/utils';
import { StateCreator } from 'zustand';
import { toChecksumAddress } from 'ethereum-checksum-address';

import { selectCurrentChainIdV3MarketData, selectFormattedReserves } from './poolSelectors';
import { RootStore } from './root';
import { ERC721Service, UniswapV3Leverage } from '@yldr/contract-helpers';
import {
  INonfungiblePositionManager
} from '@yldr/contract-helpers/src/positionManagerContract/typechain/INonfungiblePositionManager';

// TODO: what is the better name for this type?
export type PoolReserve = {
  reserves?: ReserveDataHumanized[];
  reserveIncentives?: ReservesIncentiveDataHumanized[];
  baseCurrencyData?: PoolBaseCurrencyHumanized;
  userReserves?: UserReserveDataHumanized[];
  userERC1155Reserves?: UserERC1155ReserveDataHumanized[];
};

export const MAX_SWAP_SLIPPAGE = 50;

type GenerateApprovalOpts = {
  chainId?: number;
};

type GenerateSignatureRequestOpts = {
  chainId?: number;
};

// TODO: add chain/provider/account mapping
export interface PoolSlice {
  data: Map<number, Map<string, PoolReserve>>;
  refreshPoolData: (marketData?: MarketDataType) => Promise<void>;
  refreshPoolV3Data: () => Promise<void>;
  // methods
  useOptimizedPath: () => boolean | undefined;
  mint: (args: Omit<FaucetParamsType, 'userAddress'>) => Promise<EthereumTransactionTypeExtended[]>;
  withdraw: (
    args: Omit<LPWithdrawParamsType, 'user'>
  ) => Promise<EthereumTransactionTypeExtended[]>;
  setUsageAsCollateral: (
    args: Omit<LPSetUsageAsCollateral, 'user'>
  ) => Promise<EthereumTransactionTypeExtended[]>;
  signERC20Approval: (args: Omit<LPSignERC20ApprovalType, 'user'>) => Promise<string>;
  claimRewards: (args: ClaimRewardsActionsProps) => Promise<EthereumTransactionTypeExtended[]>;
  repay: (args: RepayActionProps) => Promise<EthereumTransactionTypeExtended[]>;
  repayWithPermit: (
    args: RepayActionProps & {
      signature: SignatureLike;
      deadline: string;
    }
  ) => Promise<EthereumTransactionTypeExtended[]>;
  poolComputed: {
    minRemainingBaseTokenBalance: string;
  };
  generateSignatureRequest: (
    args: {
      token: string;
      amount: string;
      deadline: string;
      spender: string;
    },
    opts?: GenerateSignatureRequestOpts
  ) => Promise<string>;
  // PoolBundle and LendingPoolBundle methods
  generateApproval: (args: ApproveType, opts?: GenerateApprovalOpts) => PopulatedTransaction;
  generatePositionApproval: (args: ApproveType, opts?: GenerateApprovalOpts) => PopulatedTransaction;
  supply: (args: Omit<LPSupplyParamsType, 'user'>) => PopulatedTransaction;
  supplyWithPermit: (args: Omit<LPSupplyWithPermitType, 'user'>) => PopulatedTransaction;
  leveragePosition: (
    fallbackFlashloanProvider: string,
    marketId: number,
    tokenId: string,
    tokenToBorrow: string,
    amountToBorrow: string,
    slippage: number,
  ) => PopulatedTransaction;
  increaseLiquidity: (
    marketId: number,
    increaseParams: INonfungiblePositionManager.IncreaseLiquidityParamsStructOutput,
  ) => PopulatedTransaction;
  createAndLeveragePosition: (
    fallbackFlashloanProvider: string,
    marketId: number,
    mintParams: INonfungiblePositionManager.MintParamsStruct,
    tokenToBorrow: string,
    amountToBorrow: string,
    slippage: number,
  ) => PopulatedTransaction;
  createAndLeverageAlmPosition: (
    createAndLeverageAddress: string,
    mintParams: IALMCreateAndLeverageMintParams,
  ) => PopulatedTransaction;
  rebalancePosition: (
    fallbackFlashloanProvider: string,
    marketId: number,
    positionAddress: string,
    newTickLower: number,
    newTickUpper: number,
    slippage: number,
  ) => PopulatedTransaction;
  claimFees: (
    fallbackFlashloanProvider: string,
    marketId: number,
    positionAddress: string,
  ) => PopulatedTransaction;
  deleveragePosition: (
    fallbackFlashloanProvider: string,
    marketId: number,
    positionAddress: string,
    slippage: number,
  ) => PopulatedTransaction;
  deleverageAlmPosition: (
    fallbackFlashloanProvider: string,
    marketId: number,
    positionAddress: string,
    slippage: number,
  ) => PopulatedTransaction;
  closePosition: (
    fallbackFlashloanProvider: string,
    marketId: number,
    positionAddress: string,
    slippage: number,
  ) => PopulatedTransaction;
  closeAlmPosition: (
    fallbackFlashloanProvider: string,
    marketId: number,
    positionAddress: string,
    slippage: number,
  ) => PopulatedTransaction;
  compoundPosition: (
    fallbackFlashloanProvider: string,
    marketId: number,
    positionAddress: string,
    slippage: number,
  ) => PopulatedTransaction;
  setupAutoCompound: (
    marketId: number,
    position: string,
    maxTotalFeePercent: string,
  ) => PopulatedTransaction;
  cancelAutoCompound: (
    marketId: number,
    position: string,
  ) => PopulatedTransaction;
  setupAutoRebalance: (
    args: {
      marketId: number;
      positionAddress: string;
      triggerLower: number;
      triggerUpper: number;
      newTicksDown: number;
      newTicksUp: number;
      recurring: {
        rangeConfig: RangeConfigStruct;
        endConfig: EndConfigStruct;
        active: boolean;
      };
      gasFeeConfig: {
        maxUsd: number;
        maxPositionPercent: number;
      };
    }
  ) => PopulatedTransaction;
  setupAutoExit: (
    args: {
      marketId: number;
      positionAddress: string;
      triggerLower: number;
      triggerUpper: number;
      withdrawLiquidity: boolean;
      gasFeeConfig: {
        maxUsd: number;
        maxPositionPercent: number;
      };
    }
  ) => PopulatedTransaction;
  cancelAutoRebalance: (
    marketId: number,
    position: string,
  ) => PopulatedTransaction;
  cancelAutoExit: (
    marketId: number,
    position: string,
  ) => PopulatedTransaction;
  supplyPosition: (marketId: number, tokenId: string) => PopulatedTransaction;
  withdrawPosition: (
    args: { marketId: number; tokenId: string; nTokenAddress: string; balance: string; }
  ) => PopulatedTransaction;
  getApprovedAmount: (args: { token: string }) => Promise<ApproveType>;
  getPoolApprovedAmount: (args: { marketId: number, poolAddress?: string, token: string, isIncreaseLiquidity?: boolean }) => Promise<ApproveType>;
  borrow: (args: Omit<LPBorrowParamsType, 'user'>) => PopulatedTransaction;
  getCreditDelegationApprovedAmount: (
    args: Omit<ApproveDelegationType, 'user' | 'amount'>
  ) => Promise<ApproveDelegationType>;
  generateCreditDelegationSignatureRequest: (
    approval: Approval & {
      deadline: string;
      spender: string;
    }
  ) => Promise<string>;
  generateApproveDelegation: (args: Omit<ApproveDelegationType, 'user'>) => PopulatedTransaction;
  estimateGasLimit: (tx: PopulatedTransaction, chainId?: number) => Promise<PopulatedTransaction>;
}

export const createPoolSlice: StateCreator<
  RootStore,
  [['zustand/subscribeWithSelector', never], ['zustand/devtools', never]],
  [],
  PoolSlice
> = (set, get) => {
  function getCorrectPool() {
    const currentMarketData = get().currentMarketData;
    const provider = get().jsonRpcProvider();
    return new Pool(provider, {
      POOL: currentMarketData.addresses.LENDING_POOL,
      WETH_GATEWAY: currentMarketData.addresses.WETH_GATEWAY,
    });
  }
  function getCorrectPoolBundle() {
    const currentMarketData = get().currentMarketData;
    const provider = get().jsonRpcProvider();
    return new PoolBundle(provider, {
      POOL: currentMarketData.addresses.LENDING_POOL,
      WETH_GATEWAY: currentMarketData.addresses.WETH_GATEWAY,
    });
  }
  function getPoolBundleByAddress(poolAddress: string) {
    const provider = get().jsonRpcProvider();
    return new PoolBundle(provider, {
      POOL: poolAddress,
    });
  }
  function getCorrectPositionPoolBundle(marketId: number, isIncreaseLiquidity?: boolean) {
    const marketData = get().getMarketDataById(marketId);
    const provider = get().jsonRpcProvider();
    return new PoolBundle(provider, {
      POOL: isIncreaseLiquidity
        ? marketData.addresses.positionManagerAddress
        : marketData.addresses.createAndLeverageAddress,
    });
  }
  return {
    data: new Map(),
    refreshPoolData: async (marketData?: MarketDataType) => {
      const account = get().account;
      const currentChainId = get().currentChainId;
      const currentMarketData = marketData || get().currentMarketData;
      const poolDataProviderContract = new UiPoolDataProvider({
        uiPoolDataProviderAddress: currentMarketData.addresses.UI_POOL_DATA_PROVIDER,
        provider: get().jsonRpcProvider(),
        chainId: currentChainId,
      });
      // const uiIncentiveDataProviderContract = new UiIncentiveDataProvider({
      //   uiIncentiveDataProviderAddress:
      //     currentMarketData.addresses.UI_INCENTIVE_DATA_PROVIDER || '',
      //   provider: get().jsonRpcProvider(),
      //   chainId: currentChainId,
      // });
      const lendingPoolAddressProvider = currentMarketData.addresses.LENDING_POOL_ADDRESS_PROVIDER;
      const promises: Promise<void>[] = [];
      try {
        promises.push(
          poolDataProviderContract
            .getReservesHumanized({
              lendingPoolAddressProvider,
            })
            .then((reservesResponse) =>
              set((state) =>
                produce(state, (draft) => {
                  if (!draft.data.get(currentChainId)) draft.data.set(currentChainId, new Map());
                  if (!draft.data.get(currentChainId)?.get(lendingPoolAddressProvider)) {
                    draft.data.get(currentChainId)!.set(lendingPoolAddressProvider, {
                      reserves: reservesResponse.reservesData,
                      baseCurrencyData: reservesResponse.baseCurrencyData,
                    });
                  } else {
                    draft.data.get(currentChainId)!.get(lendingPoolAddressProvider)!.reserves =
                      reservesResponse.reservesData;
                    draft.data
                      .get(currentChainId)!
                      .get(lendingPoolAddressProvider)!.baseCurrencyData =
                      reservesResponse.baseCurrencyData;
                  }
                })
              )
            )
        );
        // promises.push(
        //   uiIncentiveDataProviderContract
        //     .getReservesIncentivesDataHumanized({
        //       lendingPoolAddressProvider: currentMarketData.addresses.LENDING_POOL_ADDRESS_PROVIDER,
        //     })
        //     .then((reserveIncentivesResponse) =>
        //       set((state) =>
        //         produce(state, (draft) => {
        //           if (!draft.data.get(currentChainId)) draft.data.set(currentChainId, new Map());
        //           if (!draft.data.get(currentChainId)?.get(lendingPoolAddressProvider)) {
        //             draft.data.get(currentChainId)!.set(lendingPoolAddressProvider, {
        //               reserveIncentives: reserveIncentivesResponse,
        //             });
        //           } else {
        //             draft.data
        //               .get(currentChainId)!
        //               .get(lendingPoolAddressProvider)!.reserveIncentives =
        //               reserveIncentivesResponse;
        //           }
        //         })
        //       )
        //     )
        // );
        if (account) {
          promises.push(
            poolDataProviderContract
              .getUserReservesHumanized({
                lendingPoolAddressProvider,
                user: account,
              })
              .then((userReservesResponse) =>
                set((state) =>
                  produce(state, (draft) => {
                    if (!draft.data.get(currentChainId)) draft.data.set(currentChainId, new Map());
                    if (!draft.data.get(currentChainId)?.get(lendingPoolAddressProvider)) {
                      draft.data.get(currentChainId)!.set(lendingPoolAddressProvider, {
                        userReserves: userReservesResponse.userReserves,
                        userERC1155Reserves: userReservesResponse.userERC1155Reserves,
                      });
                    } else {
                      draft.data
                        .get(currentChainId)!
                        .get(lendingPoolAddressProvider)!.userReserves =
                        userReservesResponse.userReserves;
                      draft.data
                        .get(currentChainId)!
                        .get(lendingPoolAddressProvider)!.userERC1155Reserves =
                        userReservesResponse.userERC1155Reserves;
                    }
                  })
                )
              )
          );
        }
        await Promise.all(promises);
      } catch (e) {
        console.log('error fetching pool data', e);
      }
    },
    refreshPoolV3Data: async () => {
      const v3MarketData = selectCurrentChainIdV3MarketData(get());
      get().refreshPoolData(v3MarketData);
    },
    generateApproval: (args: ApproveType, ops = {}) => {
      const provider = get().jsonRpcProvider(ops.chainId);
      const tokenERC20Service = new ERC20Service(provider);
      return tokenERC20Service.approveTxData({ ...args, amount: MAX_UINT_AMOUNT });
    },
    generatePositionApproval: (args: ApproveType, ops = {}) => {
      const provider = get().jsonRpcProvider(ops.chainId);
      const tokenERC20Service = new ERC20Service(provider);
      return tokenERC20Service.approveTxData({ ...args });
    },
    supply: (args: Omit<LPSupplyParamsType, 'user'>) => {
      const poolBundle = getCorrectPoolBundle();
      const currentAccount = get().account;
      return poolBundle.supplyTxBuilder.generateTxData({
        user: currentAccount,
        reserve: args.reserve,
        amount: args.amount,
      });
    },
    supplyWithPermit: (args: Omit<LPSupplyWithPermitType, 'user'>) => {
      const poolBundle = getCorrectPoolBundle() as PoolBundle;
      const user = get().account;
      const signature = utils.joinSignature(args.signature);
      return poolBundle.supplyTxBuilder.generateSignedTxData({
        reserve: args.reserve,
        amount: args.amount,
        user,
        deadline: args.deadline,
        signature,
      });
    },
    leveragePosition: (
      fallbackFlashloanProvider,
      marketId,
      tokenId,
      tokenToBorrow,
      amountToBorrow,
      slippage,
    ) => {
      const currentMarketData = get().currentMarketData;
      const marketData = get().getMarketDataById(marketId);
      const currentAccount = get().account;
      const provider = get().jsonRpcProvider();
      const leverageService = new UniswapV3Leverage(
        provider,
        marketData.addresses.leverageAddress,
        fallbackFlashloanProvider,
        currentMarketData.addresses.ASSET_CONVERTER,
        marketData.addresses.positionManagerAddress,
      );

      return leverageService.leverageTxData({
        maxSwapSlippage: slippage,
        user: toChecksumAddress(currentAccount),
        tokenId,
        tokenToBorrow,
        amountToBorrow,
      });
    },
    increaseLiquidity: (marketId, increaseParams) => {
      const marketData = get().getMarketDataById(marketId);
      const currentAccount = get().account;
      const provider = get().jsonRpcProvider();
      const positionManagerService = new PositionManagerContract(
        provider,
        marketData.addresses.positionManagerAddress,
      );

      const data = {
        user: toChecksumAddress(currentAccount),
        increaseParams,
      };

      return positionManagerService.increaseLiquidityTxData(data);
    },
    createAndLeveragePosition: (
      fallbackFlashloanProvider,
      marketId,
      mintParams,
      tokenToBorrow,
      amountToBorrow,
      slippage,
    ) => {
      const currentMarketData = get().currentMarketData;
      const marketData = get().getMarketDataById(marketId);
      const currentAccount = get().account;
      const provider = get().jsonRpcProvider();
      const leverageService = new PositionManagerLeverageWrapper(
        provider,
        marketData.addresses.createAndLeverageAddress,
        fallbackFlashloanProvider,
        currentMarketData.addresses.ASSET_CONVERTER,
      );

      const data = {
        user: toChecksumAddress(currentAccount),
        mintParams,
        maxSwapSlippage: slippage,
        tokenToBorrow,
        amountToBorrow,
      };

      return leverageService.mintAndLeverageTxData(data);
    },
    createAndLeverageAlmPosition: (
      createAndLeverageAddress,
      mintParams,
    ) => {
      const provider = get().jsonRpcProvider();
      const createAndLeverageService = new ALMCreateAndLeverage({
        createAndLeverageAddress,
        provider
      });

      return createAndLeverageService.mint(mintParams);
    },
    rebalancePosition: (
      fallbackFlashloanProvider,
      marketId,
      positionAddress,
      newTickLower,
      newTickUpper,
      slippage,
    ) => {
      const currentMarketData = get().currentMarketData;
      const marketData = get().getMarketDataById(marketId);
      const currentAccount = get().account;
      const provider = get().jsonRpcProvider();
      const leverageService = new UniswapV3Leverage(
        provider,
        marketData.addresses.leverageAddress,
        fallbackFlashloanProvider,
        currentMarketData.addresses.ASSET_CONVERTER,
        marketData.addresses.positionManagerAddress,
      );

      const data = {
        user: toChecksumAddress(currentAccount),
        position: toChecksumAddress(positionAddress),
        maxSwapSlippage: slippage,
        newTickLower,
        newTickUpper,
      };

      return leverageService.rebalanceTxData(data);
    },
    claimFees: (
      fallbackFlashloanProvider,
      marketId,
      positionAddress,
    ) => {
      const currentMarketData = get().currentMarketData;
      const marketData = get().getMarketDataById(marketId);
      const currentAccount = get().account;
      const provider = get().jsonRpcProvider();
      const leverageService = new UniswapV3Leverage(
        provider,
        marketData.addresses.leverageAddress,
        fallbackFlashloanProvider,
        currentMarketData.addresses.ASSET_CONVERTER,
        marketData.addresses.positionManagerAddress,
      );

      return leverageService.claimFeesTxData({
        user: toChecksumAddress(currentAccount),
        position: toChecksumAddress(positionAddress),
        maxSwapSlippage: MAX_SWAP_SLIPPAGE,
      });
    },
    deleveragePosition: (
      fallbackFlashloanProvider,
      marketId,
      positionAddress,
      slippage,
    ) => {
      const currentMarketData = get().currentMarketData;
      const marketData = get().getMarketDataById(marketId);
      const currentAccount = get().account;
      const provider = get().jsonRpcProvider();
      const leverageService = new UniswapV3Leverage(
        provider,
        marketData.addresses.leverageAddress,
        fallbackFlashloanProvider,
        currentMarketData.addresses.ASSET_CONVERTER,
        marketData.addresses.positionManagerAddress,
      );

      return leverageService.deleverageTxData({
        user: toChecksumAddress(currentAccount),
        position: toChecksumAddress(positionAddress),
        maxSwapSlippage: slippage,
        withdrawLiquidity: false,
      });
    },
    deleverageAlmPosition: (
      fallbackFlashloanProvider,
      marketId,
      positionAddress,
      slippage,
    ) => {
      const currentMarketData = get().currentMarketData;
      const almMarketData = get().getAlmMarketDataById(marketId);
      const currentAccount = get().account;
      const provider = get().jsonRpcProvider();
      const leverageService = new ALMLeveragedPosition(
        provider,
        almMarketData.addresses.leverageAddress,
        fallbackFlashloanProvider,
        currentMarketData.addresses.ASSET_CONVERTER,
      );

      return leverageService.deleverageTxData({
        user: toChecksumAddress(currentAccount),
        position: toChecksumAddress(positionAddress),
        maxSwapSlippage: slippage,
        withdrawLiquidity: false,
      });
    },
    closePosition: (
      fallbackFlashloanProvider,
      marketId,
      positionAddress,
      slippage,
    ) => {
      const currentMarketData = get().currentMarketData;
      const marketData = get().getMarketDataById(marketId);
      const currentAccount = get().account;
      const provider = get().jsonRpcProvider();
      const leverageService = new UniswapV3Leverage(
        provider,
        marketData.addresses.leverageAddress,
        fallbackFlashloanProvider,
        currentMarketData.addresses.ASSET_CONVERTER,
        marketData.addresses.positionManagerAddress,
      );

      return leverageService.deleverageTxData({
        user: toChecksumAddress(currentAccount),
        position: toChecksumAddress(positionAddress),
        maxSwapSlippage: slippage,
        withdrawLiquidity: true,
      });
    },
    closeAlmPosition: (
      fallbackFlashloanProvider,
      marketId,
      positionAddress,
      slippage,
    ) => {
      const currentMarketData = get().currentMarketData;
      const almMarketData = get().getAlmMarketDataById(marketId);
      const currentAccount = get().account;
      const provider = get().jsonRpcProvider();
      const leverageService = new ALMLeveragedPosition(
        provider,
        almMarketData.addresses.leverageAddress,
        fallbackFlashloanProvider,
        currentMarketData.addresses.ASSET_CONVERTER,
      );

      return leverageService.deleverageTxData({
        user: toChecksumAddress(currentAccount),
        position: toChecksumAddress(positionAddress),
        maxSwapSlippage: slippage,
        withdrawLiquidity: true,
      });
    },
    compoundPosition: (
      fallbackFlashloanProvider,
      marketId,
      positionAddress,
      slippage,
    ) => {
      const currentMarketData = get().currentMarketData;
      const marketData = get().getMarketDataById(marketId);
      const currentAccount = get().account;
      const provider = get().jsonRpcProvider();
      const compoundService = new UniswapV3Leverage(
        provider,
        marketData.addresses.leverageAddress,
        fallbackFlashloanProvider,
        currentMarketData.addresses.ASSET_CONVERTER,
        marketData.addresses.positionManagerAddress,
      );

      return compoundService.compoundTxData({
        user: toChecksumAddress(currentAccount),
        position: toChecksumAddress(positionAddress),
        maxSwapSlippage: slippage,
      });
    },
    setupAutoCompound: (marketId, position, maxTotalFeePercent) => {
      const provider = get().jsonRpcProvider();
      const currentAccount = get().account;
      const marketData = get().getMarketDataById(marketId);
      const leverageAutomationsService = new YLDRLeverageAutomations({
        provider,
        leverageAutomateContractAddress: marketData.addresses.leverageAutomationsAddress,
      });

      return leverageAutomationsService.setupCompoundTxData({
        user: currentAccount,
        position,
        maxTotalFeePercent,
      });
    },
    cancelAutoCompound: (marketId, position) => {
      const provider = get().jsonRpcProvider();
      const currentAccount = get().account;
      const marketData = get().getMarketDataById(marketId);
      const leverageAutomationsService = new YLDRLeverageAutomations({
        provider,
        leverageAutomateContractAddress: marketData.addresses.leverageAutomationsAddress,
      });

      return leverageAutomationsService.cancelCompoundTxData({
        user: currentAccount,
        position,
      });
    },
    setupAutoRebalance: ({
      marketId,
      positionAddress ,
      triggerLower,
      triggerUpper,
      newTicksDown,
      newTicksUp,
      recurring,
      gasFeeConfig,
    }) => {
      const provider = get().jsonRpcProvider();
      const currentAccount = get().account;
      const marketData = get().getMarketDataById(marketId);
      const leverageAutomationsService = new YLDRLeverageAutomations({
        provider,
        leverageAutomateContractAddress: marketData.addresses.leverageAutomationsAddress,
      });

      return leverageAutomationsService.setupRebalanceTxData({
        user: currentAccount,
        position: positionAddress,
        triggerLower,
        triggerUpper,
        newTicksDown,
        newTicksUp,
        recurring,
        gasFeeConfig: {
          maxUsd: Math.round(gasFeeConfig.maxUsd * (10 ** USD_DECIMALS)),
          maxPositionPercent: gasFeeConfig.maxPositionPercent,
        },
      });
    },
    cancelAutoRebalance: (marketId, position) => {
      const provider = get().jsonRpcProvider();
      const currentAccount = get().account;
      const marketData = get().getMarketDataById(marketId);
      const leverageAutomationsService = new YLDRLeverageAutomations({
        provider,
        leverageAutomateContractAddress: marketData.addresses.leverageAutomationsAddress,
      });

      return leverageAutomationsService.cancelRebalanceTxData({
        user: currentAccount,
        position,
      });
    },
    setupAutoExit: ({
      marketId,
      positionAddress,
      triggerLower,
      triggerUpper,
      withdrawLiquidity,
      gasFeeConfig,
    }) => {
      const provider = get().jsonRpcProvider();
      const currentAccount = get().account;
      const marketData = get().getMarketDataById(marketId);
      const leverageAutomationsService = new YLDRLeverageAutomations({
        provider,
        leverageAutomateContractAddress: marketData.addresses.leverageAutomationsAddress,
      });

      return leverageAutomationsService.setupDeleverageTxData({
        user: currentAccount,
        position: positionAddress,
        triggerLower,
        triggerUpper,
        withdrawLiquidity,
        gasFeeConfig: {
          maxUsd: Math.round(gasFeeConfig.maxUsd * (10 ** USD_DECIMALS)),
          maxPositionPercent: gasFeeConfig.maxPositionPercent,
        },
      });
    },
    cancelAutoExit: (marketId, position) => {
      const provider = get().jsonRpcProvider();
      const currentAccount = get().account;
      const marketData = get().getMarketDataById(marketId);
      const leverageAutomationsService = new YLDRLeverageAutomations({
        provider,
        leverageAutomateContractAddress: marketData.addresses.leverageAutomationsAddress,
      });

      return leverageAutomationsService.cancelDeleverageTxData({
        user: currentAccount,
        position,
      });
    },
    supplyPosition: (marketId, tokenId) => {
      const currentAccount = get().account;
      const marketData = get().getMarketDataById(marketId);
      const provider = get().jsonRpcProvider();
      const erc721Service = new ERC721Service(provider);
      return erc721Service.transferTxData({
        user: toChecksumAddress(currentAccount),
        token: marketData.addresses.positionManagerAddress,
        tokenId,
        to: marketData.addresses.depositZapAddress,
        params: '0x',
      });
    },
    withdrawPosition: ({
      marketId,
      tokenId,
      nTokenAddress,
      balance,
    }) => {
      const currentAccount = get().account;
      const marketData = get().getMarketDataById(marketId);
      const provider = get().jsonRpcProvider();
      const erc1155Service = new ERC1155Service(provider);

      return erc1155Service.transferTxData({
          user: toChecksumAddress(currentAccount),
          token: toChecksumAddress(nTokenAddress),
          tokenId,
          amount: balance,
          to: marketData.addresses.depositZapAddress,
          params: '0x',
      });
    },
    getApprovedAmount: async (args: { token: string }) => {
      const poolBundle = getCorrectPoolBundle();
      const user = get().account;
      return poolBundle.supplyTxBuilder.getApprovedAmount({ user, token: args.token });
    },
    getPoolApprovedAmount: async (args: {
      marketId: number,
      poolAddress?: string,
      token: string,
      isIncreaseLiquidity?: boolean,
    }) => {
      const poolBundle = args.poolAddress
        ? getPoolBundleByAddress(args.poolAddress)
        : getCorrectPositionPoolBundle(args.marketId, args.isIncreaseLiquidity);
      const user = get().account;
      return poolBundle.supplyTxBuilder.getApprovedAmount({ user, token: args.token });
    },
    borrow: (args: Omit<LPBorrowParamsType, 'user'>) => {
      const poolBundle = getCorrectPoolBundle();
      const currentAccount = get().account;
      return poolBundle.borrowTxBuilder.generateTxData({
        ...args,
        user: currentAccount,
      });
    },
    getCreditDelegationApprovedAmount: async (
      args: Omit<ApproveDelegationType, 'user' | 'amount'>
    ) => {
      const provider = get().jsonRpcProvider();
      const tokenERC20Service = new ERC20Service(provider);
      const debtTokenService = new BaseDebtToken(provider, tokenERC20Service);
      const user = get().account;
      const approvedAmount = await debtTokenService.approvedDelegationAmount({
        ...args,
        user,
      });
      return { ...args, user, amount: approvedAmount.toString() };
    },
    generateApproveDelegation: (args: Omit<ApproveDelegationType, 'user'>) => {
      const provider = get().jsonRpcProvider();
      const tokenERC20Service = new ERC20Service(provider);
      const debtTokenService = new BaseDebtToken(provider, tokenERC20Service);
      return debtTokenService.generateApproveDelegationTxData({ ...args, user: get().account });
    },
    mint: async (args) => {
      const { jsonRpcProvider, currentMarketData, account: userAddress } = get();

      if (!currentMarketData.addresses.FAUCET)
        throw Error('currently selected market does not have a faucet attached');

      if (currentMarketData.v3) {
        const v3Service = new V3FaucetService(
          jsonRpcProvider(),
          currentMarketData.addresses.FAUCET
        );
        return v3Service.mint({ ...args, userAddress });
      } else {
        const service = new FaucetService(jsonRpcProvider(), currentMarketData.addresses.FAUCET);
        return service.mint({ ...args, userAddress });
      }
    },
    withdraw: (args) => {
      const pool = getCorrectPool();
      const user = get().account;
      return pool.withdraw({
        ...args,
        user,
      });
    },
    setUsageAsCollateral: async (args) => {
      const pool = getCorrectPool();
      const user = get().account;
      return pool.setUsageAsCollateral({
        ...args,
        user,
      });
    },
    generateCreditDelegationSignatureRequest: async ({
      amount,
      deadline,
      underlyingAsset,
      spender,
    }) => {
      const user = get().account;
      const { getTokenData } = new ERC20Service(get().jsonRpcProvider());

      const { name } = await getTokenData(underlyingAsset);
      const chainId = get().currentChainId;

      const erc20_2612Service = new ERC20_2612Service(get().jsonRpcProvider());

      const nonce = await erc20_2612Service.getNonce({
        token: underlyingAsset,
        owner: user,
      });

      const typedData = {
        types: {
          EIP712Domain: [
            { name: 'name', type: 'string' },
            { name: 'version', type: 'string' },
            { name: 'chainId', type: 'uint256' },
            { name: 'verifyingContract', type: 'address' },
          ],
          DelegationWithSig: [
            { name: 'delegatee', type: 'address' },
            { name: 'value', type: 'uint256' },
            { name: 'nonce', type: 'uint256' },
            { name: 'deadline', type: 'uint256' },
          ],
        },
        primaryType: 'DelegationWithSig' as const,
        domain: {
          name,
          version: '1',
          chainId: chainId,
          verifyingContract: underlyingAsset,
        },
        message: {
          delegatee: spender,
          value: amount,
          nonce,
          deadline,
        },
      };

      return JSON.stringify(typedData);
    },
    repay: ({ repayWithYTokens, amountToRepay, poolAddress }) => {
      const pool = getCorrectPool();
      const currentAccount = get().account;
      if (pool instanceof Pool && repayWithYTokens) {
        return pool.repayWithYTokens({
          user: currentAccount,
          reserve: poolAddress,
          amount: amountToRepay,
        });
      } else {
        return pool.repay({
          user: currentAccount,
          reserve: poolAddress,
          amount: amountToRepay,
        });
      }
    },
    repayWithPermit: ({ poolAddress, amountToRepay, deadline, signature }) => {
      // Better to get rid of direct assert
      const pool = getCorrectPool() as Pool;
      const currentAccount = get().account;
      return pool.repayWithPermit({
        user: currentAccount,
        reserve: poolAddress,
        amount: amountToRepay, // amountToRepay.toString(),
        signature,
        deadline,
      });
    },
    signERC20Approval: async (args) => {
      const pool = getCorrectPool() as Pool;
      const user = get().account;
      return pool.signERC20Approval({
        ...args,
        user,
      });
    },
    claimRewards: async ({ selectedReward }) => {
      // TODO: think about moving timestamp from hook to EventEmitter
      const timestamp = dayjs().unix();
      const reserves = selectFormattedReserves(get(), timestamp);
      const currentAccount = get().account;

      const allReserves: string[] = [];
      reserves.forEach((reserve) => {
        if (reserve.aIncentivesData && reserve.aIncentivesData.length > 0) {
          allReserves.push(reserve.yTokenAddress);
        }
        if (reserve.vIncentivesData && reserve.vIncentivesData.length > 0) {
          allReserves.push(reserve.variableDebtTokenAddress);
        }
      });

      const incentivesTxBuilder = new IncentivesController(get().jsonRpcProvider());
      const incentivesTxBuilderV2: IncentivesControllerV2Interface = new IncentivesControllerV2(
        get().jsonRpcProvider()
      );

      if (get().currentMarketData.v3) {
        if (selectedReward.symbol === 'all') {
          return incentivesTxBuilderV2.claimAllRewards({
            user: currentAccount,
            assets: allReserves,
            to: currentAccount,
            incentivesControllerAddress: selectedReward.incentiveControllerAddress,
          });
        } else {
          return incentivesTxBuilderV2.claimRewards({
            user: currentAccount,
            assets: allReserves,
            to: currentAccount,
            incentivesControllerAddress: selectedReward.incentiveControllerAddress,
            reward: selectedReward.rewardTokenAddress,
          });
        }
      } else {
        return incentivesTxBuilder.claimRewards({
          user: currentAccount,
          assets: selectedReward.assets,
          to: currentAccount,
          incentivesControllerAddress: selectedReward.incentiveControllerAddress,
        });
      }
    },
    useOptimizedPath: () => {
      return get().currentMarketData.v3 && optimizedPath(get().currentChainId);
    },
    poolComputed: {
      get minRemainingBaseTokenBalance() {
        if (!get()) return '0.001';
        const { currentNetworkConfig, currentChainId } = { ...get() };
        const chainId = currentNetworkConfig.underlyingChainId || currentChainId;
        const min = minBaseTokenRemainingByNetwork[chainId];
        return min || '0.001';
      },
    },
    generateSignatureRequest: async ({ token, amount, deadline, spender }, opts = {}) => {
      const provider = get().jsonRpcProvider(opts.chainId);
      const tokenERC20Service = new ERC20Service(provider);
      const tokenERC2612Service = new ERC20_2612Service(provider);
      const { name } = await tokenERC20Service.getTokenData(token);
      const { chainId } = await provider.getNetwork();
      const nonce = await tokenERC2612Service.getNonce({ token, owner: get().account });
      const typeData = {
        types: {
          EIP712Domain: [
            { name: 'name', type: 'string' },
            { name: 'version', type: 'string' },
            { name: 'chainId', type: 'uint256' },
            { name: 'verifyingContract', type: 'address' },
          ],
          Permit: [
            { name: 'owner', type: 'address' },
            { name: 'spender', type: 'address' },
            { name: 'value', type: 'uint256' },
            { name: 'nonce', type: 'uint256' },
            { name: 'deadline', type: 'uint256' },
          ],
        },
        primaryType: 'Permit',
        domain: {
          name,
          version: '1',
          chainId,
          verifyingContract: token,
        },
        message: {
          owner: get().account,
          spender: spender,
          value: amount,
          nonce,
          deadline,
        },
      };
      return JSON.stringify(typeData);
    },
    estimateGasLimit: async (tx: PopulatedTransaction, chainId?: number) => {
      const provider = get().jsonRpcProvider(chainId);
      const defaultGasLimit: BigNumber = tx.gasLimit ? tx.gasLimit : BigNumber.from('0');
      delete tx.gasLimit;
      let estimatedGas = await provider.estimateGas(tx);
      estimatedGas = estimatedGas.mul(115).div(100); // Add 15% buffer
      // use the max of the 2 values, airing on the side of caution to prioritize having enough gas vs submitting w/ most efficient gas limit
      tx.gasLimit = estimatedGas.gt(defaultGasLimit) ? estimatedGas : defaultGasLimit;
      return tx;
    },
  };
};
