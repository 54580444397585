import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../ui-config/queries';
import { useProtocolDataContext } from '../useProtocolDataContext';

export interface IAlmMarketDataResponse {
  id: number;
  chain_id: number;
  name: string;
  create_and_leverage_address: string;
  leverage_address: string;
  leverage_data_provider_address: string;
}

export interface IAlmMarketData {
  chainId: number;
  marketId: number;
  marketName: string;
  addresses: {
    createAndLeverageAddress: string;
    leverageAddress: string;
    leverageDataProviderAddress: string;
  }
}

const INITIAL_DATA: IAlmMarketData[] = [];

export const getAlmMarkets = async (endpointURL: string) => {
  try {
    const url = `${endpointURL}/concentrated_liquidity/alm_markets`;
    const result = await fetch(url);
    const json: IAlmMarketDataResponse[] = await result.json();
    return json;
  } catch {
    return [];
  }
};

export const useAlmMarketsQuery = (chainId?: number) => {
  const { currentNetworkConfig } = useProtocolDataContext();
  const apiUrl = currentNetworkConfig.yldrYpiUrl;

  const { data, ...rest } = useQuery({
    queryFn: () => getAlmMarkets(apiUrl),
    queryKey: [QueryKeys.ALM_MARKETS, apiUrl],
    select: (response): IAlmMarketData[] => response.map((item) => ({
      chainId: item.chain_id,
      marketId: item.id,
      marketName: item.name,
      addresses: {
        createAndLeverageAddress: item.create_and_leverage_address,
        leverageAddress: item.leverage_address,
        leverageDataProviderAddress: item.leverage_data_provider_address,
      }
    })).filter((item) => !chainId || item.chainId === chainId),
    refetchOnMount: false,
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  };
};
