import { ChainId } from '@yldr/contract-helpers';
import { ReactNode } from 'react';

import * as YldrArbitrumV3 from '../chain-configs/YldrArbitrumV3';
import * as YldrMainnet from '../chain-configs/YldrMainnet';
import * as YldrPolygon from '../chain-configs/YldrPolygon';
import * as YldrBase from "../chain-configs/YldrBase";

export enum EMarketName {
  None = 'None',
  Uniswap = 'Uniswap V3',
  Camelot = 'Camelot',
  Quickswap = 'Quickswap',
}

export enum EAlmMarketName {
  Steer = 'Steer',
}

export type MarketDataType = {
  v3?: boolean;
  marketTitle: string;
  chainId: ChainId;
  enabledFeatures?: {
    liquiditySwap?: boolean;
    staking?: boolean;
    governance?: boolean;
    faucet?: boolean;
    collateralRepay?: boolean;
    incentives?: boolean;
    permissions?: boolean;
    debtSwitch?: boolean;
    withdrawAndSwitch?: boolean;
    switch?: boolean;
  };
  isFork?: boolean;
  permissionComponent?: ReactNode;
  disableCharts?: boolean;
  subgraphUrl?: string;
  addresses: {
    LENDING_POOL_ADDRESS_PROVIDER: string;
    LENDING_POOL: string;
    WETH_GATEWAY?: string;
    ASSET_CONVERTER: string;
    FALLBACK_FLASHLOAN_PROVIDER: string;
    PREFERRED_FLASHLOAN_PROVIDERS: string[];
    SWAP_COLLATERAL_ADAPTER?: string;
    REPAY_WITH_COLLATERAL_ADAPTER?: string;
    DEBT_SWITCH_ADAPTER?: string;
    WITHDRAW_SWITCH_ADAPTER?: string;
    FAUCET?: string;
    PERMISSION_MANAGER?: string;
    WALLET_BALANCE_PROVIDER: string;
    L2_ENCODER?: string;
    UI_POOL_DATA_PROVIDER: string;
    UI_INCENTIVE_DATA_PROVIDER?: string;
    COLLECTOR?: string;
    V3_MIGRATOR?: string;
    GHO_TOKEN_ADDRESS?: string;
    GHO_UI_DATA_PROVIDER?: string;
  };
  /**
   * https://www.hal.xyz/ has integrated aave for healtfactor warning notification
   * the integration doesn't follow aave market naming & only supports a subset of markets.
   * When a halIntegration is specified a link to hal will be displayed on the ui.
   */
  halIntegration?: {
    URL: string;
    marketName: string;
  };
};

export enum CustomMarket {
  // Test networks
  // proto_arbitrum_goerli_v3 = 'proto_arbitrum_goerli_v3',
  // proto_goerli_v3 = 'proto_goerli_v3',
  // Mainnets
  proto_arbitrum_v3 = 'proto_arbitrum_v3',
  proto_mainnet_v3 = 'proto_mainnet_v3',
  proto_polygon_v3 = 'proto_polygon_v3',
  proto_base_v3 = 'proto_base_v3',
}

export const marketsData: {
  [key in keyof typeof CustomMarket]: MarketDataType;
} = {
  // [CustomMarket.proto_mainnet_v3]: {
  //   marketTitle: 'Ethereum',
  //   chainId: ChainId.mainnet,
  //   v3: true,
  //   enabledFeatures: {
  //     governance: true,
  //     staking: true,
  //     liquiditySwap: true,
  //     collateralRepay: true,
  //     incentives: true,
  //     withdrawAndSwitch: true,
  //     debtSwitch: true,
  //     switch: true,
  //   },
  //   subgraphUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v3',
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: AaveV3Ethereum.POOL_ADDRESSES_PROVIDER,
  //     LENDING_POOL: AaveV3Ethereum.POOL,
  //     WETH_GATEWAY: AaveV3Ethereum.WETH_GATEWAY,
  //     REPAY_WITH_COLLATERAL_ADAPTER: AaveV3Ethereum.REPAY_WITH_COLLATERAL_ADAPTER,
  //     SWAP_COLLATERAL_ADAPTER: AaveV3Ethereum.SWAP_COLLATERAL_ADAPTER,
  //     WALLET_BALANCE_PROVIDER: AaveV3Ethereum.WALLET_BALANCE_PROVIDER,
  //     UI_POOL_DATA_PROVIDER: AaveV3Ethereum.UI_POOL_DATA_PROVIDER,
  //     UI_INCENTIVE_DATA_PROVIDER: AaveV3Ethereum.UI_INCENTIVE_DATA_PROVIDER,
  //     COLLECTOR: AaveV3Ethereum.COLLECTOR,
  //     GHO_TOKEN_ADDRESS: AaveV3Ethereum.GHO_TOKEN,
  //     GHO_UI_DATA_PROVIDER: AaveV3Ethereum.UI_GHO_DATA_PROVIDER,
  //     WITHDRAW_SWITCH_ADAPTER: AaveV3Ethereum.WITHDRAW_SWAP_ADAPTER,
  //     DEBT_SWITCH_ADAPTER: AaveV3Ethereum.DEBT_SWAP_ADAPTER,
  //   },
  //   halIntegration: {
  //     URL: 'https://app.hal.xyz/recipes/aave-v3-track-health-factor',
  //     marketName: 'aavev3',
  //   },
  // },
  [CustomMarket.proto_arbitrum_v3]: {
    marketTitle: 'Arbitrum',
    v3: true,
    chainId: ChainId.arbitrum_one,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: YldrArbitrumV3.POOL_ADDRESSES_PROVIDER,
      LENDING_POOL: YldrArbitrumV3.POOL,
      WETH_GATEWAY: YldrArbitrumV3.WETH_GATEWAY,
      ASSET_CONVERTER: YldrArbitrumV3.ASSET_CONVERTER,
      FALLBACK_FLASHLOAN_PROVIDER: YldrArbitrumV3.FALLBACK_FLASHLOAN_PROVIDER,
      PREFERRED_FLASHLOAN_PROVIDERS: YldrArbitrumV3.PREFERRED_FLASHLOAN_PROVIDERS,
      WALLET_BALANCE_PROVIDER: YldrArbitrumV3.WALLET_BALANCE_PROVIDER,
      UI_POOL_DATA_PROVIDER: YldrArbitrumV3.UI_POOL_DATA_PROVIDER,
    }
  },
  [CustomMarket.proto_mainnet_v3]: {
    marketTitle: 'Ethereum',
    v3: true,
    chainId: ChainId.mainnet,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: YldrMainnet.POOL_ADDRESSES_PROVIDER,
      LENDING_POOL: YldrMainnet.POOL,
      WETH_GATEWAY: YldrMainnet.WETH_GATEWAY,
      ASSET_CONVERTER: YldrMainnet.ASSET_CONVERTER,
      FALLBACK_FLASHLOAN_PROVIDER: YldrMainnet.FALLBACK_FLASHLOAN_PROVIDER,
      PREFERRED_FLASHLOAN_PROVIDERS: YldrMainnet.PREFERRED_FLASHLOAN_PROVIDERS,
      WALLET_BALANCE_PROVIDER: YldrMainnet.WALLET_BALANCE_PROVIDER,
      UI_POOL_DATA_PROVIDER: YldrMainnet.UI_POOL_DATA_PROVIDER,
    }
  },
  [CustomMarket.proto_polygon_v3]: {
    marketTitle: 'Polygon',
    v3: true,
    chainId: ChainId.polygon,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: YldrPolygon.POOL_ADDRESSES_PROVIDER,
      LENDING_POOL: YldrPolygon.POOL,
      WETH_GATEWAY: YldrPolygon.WETH_GATEWAY,
      ASSET_CONVERTER: YldrPolygon.ASSET_CONVERTER,
      FALLBACK_FLASHLOAN_PROVIDER: YldrPolygon.FALLBACK_FLASHLOAN_PROVIDER,
      PREFERRED_FLASHLOAN_PROVIDERS: YldrPolygon.PREFERRED_FLASHLOAN_PROVIDERS,
      WALLET_BALANCE_PROVIDER: YldrPolygon.WALLET_BALANCE_PROVIDER,
      UI_POOL_DATA_PROVIDER: YldrPolygon.UI_POOL_DATA_PROVIDER,
    }
  },
  [CustomMarket.proto_base_v3]: {
    marketTitle: 'Base',
    v3: true,
    chainId: ChainId.base,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: YldrBase.POOL_ADDRESSES_PROVIDER,
      LENDING_POOL: YldrBase.POOL,
      WETH_GATEWAY: YldrBase.WETH_GATEWAY,
      ASSET_CONVERTER: YldrBase.ASSET_CONVERTER,
      FALLBACK_FLASHLOAN_PROVIDER: YldrBase.FALLBACK_FLASHLOAN_PROVIDER,
      PREFERRED_FLASHLOAN_PROVIDERS: YldrBase.PREFERRED_FLASHLOAN_PROVIDERS,
      WALLET_BALANCE_PROVIDER: YldrBase.WALLET_BALANCE_PROVIDER,
      UI_POOL_DATA_PROVIDER: YldrBase.UI_POOL_DATA_PROVIDER,
    }
  },
  // Test
  // [CustomMarket.proto_goerli_v3]: {
  //   marketTitle: 'Ethereum Görli',
  //   v3: true,
  //   chainId: ChainId.goerli,
  //   enabledFeatures: {
  //     faucet: true,
  //     incentives: true,
  //   },
  //   //subgraphUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v3-arbitrum-goerli',  needs re-deployment
  //   addresses: {
  //     UNISWAP_V3_DATA_PROVIDER: '',
  //     LENDING_POOL_ADDRESS_PROVIDER: YldrV3Goerli.POOL_ADDRESSES_PROVIDER,
  //     LENDING_POOL: YldrV3Goerli.POOL,
  //     WETH_GATEWAY: YldrV3Goerli.WETH_GATEWAY,
  //     FAUCET: YldrV3Goerli.FAUCET,
  //     WALLET_BALANCE_PROVIDER: YldrV3Goerli.WALLET_BALANCE_PROVIDER,
  //     UI_POOL_DATA_PROVIDER: YldrV3Goerli.UI_POOL_DATA_PROVIDER,
  //     // UI_INCENTIVE_DATA_PROVIDER: YldrV3Goerli.UI_INCENTIVE_DATA_PROVIDER,
  //     // L2_ENCODER: YldrV3Goerli.L2_ENCODER,
  //   },
  // },
  // [CustomMarket.proto_arbitrum_goerli_v3]: {
  //   marketTitle: 'Arbitrum Görli',
  //   v3: true,
  //   chainId: ChainId.arbitrum_goerli,
  //   enabledFeatures: {
  //     faucet: true,
  //     incentives: true,
  //   },
  //   //subgraphUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v3-arbitrum-goerli',  needs re-deployment
  //   addresses: {
  //     UNISWAP_V3_DATA_PROVIDER: '',
  //     LENDING_POOL_ADDRESS_PROVIDER: AaveV3ArbitrumGoerli.POOL_ADDRESSES_PROVIDER,
  //     LENDING_POOL: AaveV3ArbitrumGoerli.POOL,
  //     WETH_GATEWAY: AaveV3ArbitrumGoerli.WETH_GATEWAY,
  //     FAUCET: AaveV3ArbitrumGoerli.FAUCET,
  //     WALLET_BALANCE_PROVIDER: AaveV3ArbitrumGoerli.WALLET_BALANCE_PROVIDER,
  //     UI_POOL_DATA_PROVIDER: AaveV3ArbitrumGoerli.UI_POOL_DATA_PROVIDER,
  //     UI_INCENTIVE_DATA_PROVIDER: AaveV3ArbitrumGoerli.UI_INCENTIVE_DATA_PROVIDER,
  //     L2_ENCODER: AaveV3ArbitrumGoerli.L2_ENCODER,
  //   },
  // },
} as const;
