import '/public/fonts/inter/inter.css';
import '/src/styles/variables.css';
import '/src/styles/adroll.css';

import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Web3ReactProvider } from '@web3-react/core';
import { providers } from 'ethers';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { ReactNode } from 'react';
import { Meta } from 'src/components/Meta';
import { TransactionEventHandler } from 'src/components/TransactionEventHandler';
import { GasStationProvider } from 'src/components/transactions/GasStation/GasStationProvider';
import { BackgroundDataProvider } from 'src/hooks/app-data-provider/BackgroundDataProvider';
import { AppDataProvider } from 'src/hooks/app-data-provider/useAppDataProvider';
import { ModalContextProvider } from 'src/hooks/useModal';
import { PermissionProvider } from 'src/hooks/usePermissions';
import { Web3ContextProvider } from 'src/libs/web3-data-provider/Web3Provider';
import { SharedDependenciesProvider } from 'src/ui-config/SharedDependenciesProvider';

import createEmotionCache from '../src/createEmotionCache';
import { AppGlobalStyles } from '../src/layouts/AppGlobalStyles';
import { LanguageProvider } from '../src/libs/LanguageProvider';
import { BackgroundProvider } from '../src/libs/BackgroundContext/BackgroundContext';
import { BlockNumberProvider } from 'src/components-yldr/LiquidityChartRangeInput/hooks/useBlockNumber';
import { store } from '../src/components-yldr/LiquidityChartRangeInput/state/store';
import {
  ChainIdUpdater,
  MarketsUpdater,
  AlmMarketsUpdater,
} from '../src/components-yldr/LiquidityChartRangeInput/state/application/Updaters';

const BorrowModal = dynamic(() =>
  import('src/components/transactions/Borrow/BorrowModal').then((module) => module.BorrowModal)
);
const ClaimRewardsModal = dynamic(() =>
  import('src/components/transactions/ClaimRewards/ClaimRewardsModal').then(
    (module) => module.ClaimRewardsModal
  )
);
const CollateralChangeModal = dynamic(() =>
  import('src/components/transactions/CollateralChange/CollateralChangeModal').then(
    (module) => module.CollateralChangeModal
  )
);
const FaucetModal = dynamic(() =>
  import('src/components/transactions/Faucet/FaucetModal').then((module) => module.FaucetModal)
);
const RepayModal = dynamic(() =>
  import('src/components/transactions/Repay/RepayModal').then((module) => module.RepayModal)
);
const SupplyModal = dynamic(() =>
  import('src/components/transactions/Supply/SupplyModal').then((module) => module.SupplyModal)
);
const SupplyPositionModal = dynamic(() =>
  import('src/components/transactions/SupplyPosition/SupplyPositionModal').then((module) => module.SupplyPositionModal)
);
const WithdrawModal = dynamic(() =>
  import('src/components/transactions/Withdraw/WithdrawModal').then(
    (module) => module.WithdrawModal
  )
);
const AddLiquidityModal = dynamic(() =>
  import('src/components/transactions/AddLiquidity/AddLiquidityModal').then(
    (module) => module.AddLiquidityModal
  )
);
const ActionsModal = dynamic(() =>
  import('src/components/transactions/ActionsModal/ActionsModal').then(
    (module) => module.ActionsModal
  )
);
const AutomationsModal = dynamic(() =>
  import('src/components/transactions/AutomationsModal/AutomationsModal').then(
    (module) => module.AutomationsModal
  )
);
const WithdrawPositionModal = dynamic(() =>
  import('src/components/transactions/WithdrawPosition/WithdrawPositionModal').then(
    (module) => module.WithdrawPositionModal
  )
);
const ClosePositionModal = dynamic(() =>
  import('src/components/transactions/ClosePosition/ClosePositionModal').then(
    (module) => module.ClosePositionModal
  )
);
const LeveragePositionModal = dynamic(() =>
  import('src/components/transactions/LeveragePosition/LeveragePositionModal').then(
    (module) => module.LeveragePositionModal
  )
);
const DeleveragePositionModal = dynamic(() =>
  import('src/components/transactions/DeleveragePosition/DeleveragePositionModal').then(
    (module) => module.DeleveragePositionModal
  )
);
const CompoundFeesModal = dynamic(() =>
  import('src/components/transactions/CompoundFees/CompoundFeesModal').then(
    (module) => module.CompoundFeesModal
  )
);
const AutoCompoundFeesModal = dynamic(() =>
  import('src/components/transactions/AutoCompoundFees/AutoCompoundFeesModal').then(
    (module) => module.AutoCompoundFeesModal
  )
);
const AutoRebalanceModal = dynamic(() =>
  import('src/components/transactions/AutoRebalance/AutoRebalanceModal').then(
    (module) => module.AutoRebalanceModal
  )
);
const AutoExitModal = dynamic(() =>
  import('src/components/transactions/AutoExit/AutoExitModal').then(
    (module) => module.AutoExitModal
  )
);

const ClaimFeesModal = dynamic(() =>
  import('src/components/transactions/ClaimFees/ClaimFeesModal').then(
    (module) => module.ClaimFeesModal
  )
);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
let referrerSaved = false;

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getWeb3Library(provider: any): providers.Web3Provider {
  const library = new providers.Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

export const queryClient = new QueryClient();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}

Sentry.init({
  dsn: "https://9f27deb42636decbcabd9fd31b3840f8@o4505702862422016.ingest.us.sentry.io/4506938930823168",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // allowUrls: ['https://yldr.com/'],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const getLayout = Component.getLayout ?? ((page: ReactNode) => page);

  if (typeof window !== 'undefined' && !referrerSaved) {
    const referrerURL = window.document.referrer;
    const currentSearchParams = new URLSearchParams(window.location.search);
    const utmSource = currentSearchParams.get('utm_source');
    const utmMedium = currentSearchParams.get('utm_medium');
    const utmCampaign = currentSearchParams.get('utm_campaign');
    const referrer = {
      referrer_url: referrerURL,
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
    }
    localStorage.setItem('yldr_referrer', JSON.stringify(referrer));
    referrerSaved = true;
  }

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        {'<!-- Google tag (gtag.js) -->'}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-7RHDP2246Z"></script>
        <script dangerouslySetInnerHTML={{ __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-7RHDP2246Z');
          `
        }}>
        </script>
        {'<!-- Adroll -->'}
        <script dangerouslySetInnerHTML={{ __html: `
          adroll_adv_id = "VK7NBNT7LZEYFEVFJ4KGGA";
          adroll_pix_id = "KZZVMIQAJZHHDJUN46QGV2";
          adroll_version = "2.0";
          (function(w, d, e, o, a) { w.__adroll_loaded = true; w.adroll = w.adroll || []; w.adroll.f = [ 'setProperties', 'identify', 'track' ]; var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id + "/roundtrip.js"; for (a = 0; a < w.adroll.f.length; a++) { w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) { return function() { w.adroll.push([ n, arguments ]) } })(w.adroll.f[a]) }  e = d.createElement('script'); o = d.getElementsByTagName('script')[0]; e.async = 1; e.src = roundtripUrl; o.parentNode.insertBefore(e, o); })(window, document);
          adroll.track("pageView");
        `}}></script>
        <script type="text/javascript">  </script>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <Meta title={'Liquidity Protocol'} description={'Liquidity Protocol'} />
      <BackgroundProvider>
        <LanguageProvider>
        <QueryClientProvider client={queryClient}>
          <Web3ReactProvider getLibrary={getWeb3Library}>
            <Web3ContextProvider>
              <BlockNumberProvider>
                <Provider store={store}>
                  <AppGlobalStyles>
                    <PermissionProvider>
                      <ModalContextProvider>
                        <BackgroundDataProvider>
                          <AppDataProvider>
                            <GasStationProvider>
                              <SharedDependenciesProvider>
                                {getLayout(<Component {...pageProps} />)}
                                <ActionsModal />
                                <AddLiquidityModal />
                                <AutomationsModal />
                                <SupplyModal />
                                <SupplyPositionModal />
                                <WithdrawModal />
                                <WithdrawPositionModal />
                                <ClosePositionModal />
                                <LeveragePositionModal />
                                <DeleveragePositionModal />
                                <CompoundFeesModal />
                                <AutoCompoundFeesModal />
                                <AutoRebalanceModal />
                                <AutoExitModal />
                                <BorrowModal />
                                <RepayModal />
                                <CollateralChangeModal />
                                <ClaimRewardsModal />
                                <ClaimFeesModal />
                                <FaucetModal />
                                <TransactionEventHandler />
                                <ChainIdUpdater />
                                <MarketsUpdater />
                                <AlmMarketsUpdater />
                              </SharedDependenciesProvider>
                            </GasStationProvider>
                          </AppDataProvider>
                        </BackgroundDataProvider>
                      </ModalContextProvider>
                    </PermissionProvider>
                  </AppGlobalStyles>
                </Provider>
              </BlockNumberProvider>
            </Web3ContextProvider>
          </Web3ReactProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </LanguageProvider>
      </BackgroundProvider>
    </CacheProvider>
  );
}
