import { providers, utils } from 'ethers';
import { permitByChainAndToken } from 'src/ui-config/permitConfig';
import {
  availableMarkets,
  getNetworkConfig,
  getProvider,
  marketsData,
} from 'src/utils/marketsAndNetworksConfig';
import { StateCreator } from 'zustand';

import { CustomMarket, EMarketName, MarketDataType } from '../ui-config/marketsConfig';
import { IAlmMarketData } from '../hooks/yldr/useAlmMarketsQuery';
import { IMarketData } from '../hooks/yldr/useMarketsQuery';
import { NetworkConfig } from '../ui-config/networksConfig';
import { RootStore } from './root';
import { setQueryParameter } from './utils/queryParams';

type TypePermitParams = {
  reserveAddress: string;
  isWrappedBaseAsset: boolean;
};

const EMPTY_MARKET_STUB: IMarketData = {
  chainId: 0,
  marketId: 0,
  marketName: EMarketName.None,
  addresses: {
    dataProviderAddress: '',
    leverageAddress: '',
    leverageDataProviderAddress: '',
    depositZapAddress: '',
    createAndLeverageAddress: '',
    positionManagerAddress: '',
    positionWrapperAddress: '',
    leverageAutomationsAddress: '',
  },
};

const EMPTY_ALM_MARKET_STUB: IAlmMarketData = {
  chainId: 0,
  marketId: 0,
  marketName: '',
  addresses: {
    createAndLeverageAddress: '',
    leverageAddress: '',
    leverageDataProviderAddress: '',
  },
};

export interface ProtocolDataSlice {
  currentMarket: CustomMarket;
  currentMarketData: MarketDataType;
  currentChainId: number;
  currentNetworkConfig: NetworkConfig;
  jsonRpcProvider: (chainId?: number) => providers.Provider;
  isMarketsReady?: boolean;
  marketData: IMarketData[];
  isAlmMarketsReady?: boolean;
  almMarketData: IAlmMarketData[];
  getMarketDataById: (marketId?: number) => IMarketData;
  getAlmMarketDataById: (marketId?: number) => IAlmMarketData;
  getCurrentChainMarketData: () => IMarketData[];
  getCurrentChainAlmMarketData: () => IAlmMarketData[];
  updateMarketData: (marketData: IMarketData[], isMarketsReady: boolean) => void,
  updateAlmMarketData: (almMarketData: IAlmMarketData[], isAlmMarketsReady: boolean) => void,
  setCurrentMarket: (market: CustomMarket, omitQueryParameterUpdate?: boolean) => void;
  tryPermit: ({ reserveAddress, isWrappedBaseAsset }: TypePermitParams) => boolean;
}

export const createProtocolDataSlice: StateCreator<
  RootStore,
  [['zustand/subscribeWithSelector', never], ['zustand/devtools', never]],
  [],
  ProtocolDataSlice
> = (set, get) => {
  let selectedMarket;
  if (typeof document !== 'undefined') {
    selectedMarket = localStorage.getItem('selectedMarket') as CustomMarket;
  }
  const initialMarket = selectedMarket || availableMarkets[0];
  const initialMarketData = marketsData[initialMarket];
  return {
    isMarketsReady: false,
    isAlmMarketsReady: false,
    marketData: [],
    almMarketData: [],
    currentMarket: initialMarket,
    currentMarketData: marketsData[initialMarket],
    currentChainId: initialMarketData.chainId,
    currentNetworkConfig: getNetworkConfig(initialMarketData.chainId),
    jsonRpcProvider: (chainId) => getProvider(chainId ?? get().currentChainId),
    setCurrentMarket: (market, omitQueryParameterUpdate) => {
      if (!availableMarkets.includes(market as CustomMarket)) return;
      const nextMarketData = marketsData[market];
      if (!omitQueryParameterUpdate) {
        setQueryParameter('marketName', market);
      }
      if (typeof document !== 'undefined') {
        localStorage.setItem('selectedMarket', market);
      }
      set({
        currentMarket: market,
        currentMarketData: nextMarketData,
        currentChainId: nextMarketData.chainId,
        currentNetworkConfig: getNetworkConfig(nextMarketData.chainId),
      });
    },
    updateMarketData: (marketData, isMarketsReady) => {
      set({ marketData, isMarketsReady });
    },
    updateAlmMarketData: (almMarketData, isAlmMarketsReady) => {
      set({ almMarketData, isAlmMarketsReady })
    },
    getMarketDataById: (marketId) => {
      return get().marketData.find((item) => item.marketId === marketId) || EMPTY_MARKET_STUB;
    },
    getAlmMarketDataById: (marketId) => {
      return get().almMarketData.find((item) => item.marketId === marketId) || EMPTY_ALM_MARKET_STUB;
    },
    getCurrentChainMarketData: () => {
      const currentChainId = get().currentChainId;
      return get().marketData.filter((item) => item.chainId === currentChainId);
    },
    getCurrentChainAlmMarketData: () => {
      const currentChainId = get().currentChainId;
      return get().almMarketData.filter((item) => item.chainId === currentChainId);
    },
    tryPermit: ({ reserveAddress, isWrappedBaseAsset }: TypePermitParams) => {
      const currentNetworkConfig = get().currentNetworkConfig;
      const currentMarketData = get().currentMarketData;
      // current chain id, or underlying chain id for fork networks
      const underlyingChainId = currentNetworkConfig.isFork
        ? currentNetworkConfig.underlyingChainId
        : currentMarketData.chainId;
      // enable permit for all v3 test network assets (except WrappedBaseAssets) or v3 production assets included in permitConfig)
      const testnetPermitEnabled = Boolean(
        currentMarketData.v3 && currentNetworkConfig.isTestnet && !isWrappedBaseAsset
      );
      const productionPermitEnabled = Boolean(
        currentMarketData.v3 &&
          underlyingChainId &&
          permitByChainAndToken[underlyingChainId]?.[utils.getAddress(reserveAddress).toLowerCase()]
      );
      return testnetPermitEnabled || productionPermitEnabled;
    },
  };
};
