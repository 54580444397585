import { createContext, useContext, useState } from 'react';
import { useWeb3Context } from 'src/libs/hooks/useWeb3Context';
import { useRootStore } from 'src/store/root';
import { TxErrorType } from 'src/ui-config/errorMapping';
import { GENERAL } from 'src/utils/mixPanelEvents';
import { EControlPositionType } from '../types/uniswapTokens';

export enum ModalType {
  Actions,
  AddLiquidity,
  Automations,
  Supply,
  SupplyPosition,
  Withdraw,
  WithdrawPosition,
  LeveragePosition,
  DeleveragePosition,
  CompoundFees,
  AutoCompoundFees,
  Borrow,
  Repay,
  CollateralChange,
  RateSwitch,
  Stake,
  Unstake,
  StakeCooldown,
  StakeRewardClaim,
  ClaimRewards,
  Faucet,
  Swap,
  GovDelegation,
  GovVote,
  RevokeGovDelegation,
  StakeRewardsClaimRestake,
  Switch,
  CreatePosition,
  ClosePosition,
  Rebalance,
  AutoRebalance,
  AutoExit,
  ClaimFees,
}

export interface ModalArgsType {
  underlyingAsset?: string;
  proposalId?: number;
  support?: boolean;
  power?: string;
  icon?: string;
  stakeAssetName?: string;
  isFrozen?: boolean;
  marketId?: number;
  tokenId?: string;
  suppliedTokenId?: string;
  positionAddress?: string;
  controlType?: EControlPositionType;
}

export type TxStateType = {
  symbol?: string;
  txHash?: string;
  // txError?: string;
  loading?: boolean;
  success?: boolean;
  approvingToken?: string;
  value?: string;
};

export interface ModalContextType<T extends ModalArgsType> {
  createUniswapPosition: () => void;
  rebalanceUniswapPosition: () => void;
  openSupply: (
    underlyingAsset: string,
  ) => void;
  openSupplyPosition: (
    tokenId: string,
  ) => void;
  openWithdraw: (
    underlyingAsset: string,
  ) => void;
  openWithdrawPosition: (
    suppliedTokenId: string,
  ) => void;
  openLeveragePosition: (
    tokenId: string,
  ) => void;
  openActions: (
    positionAddress: string,
    controlType?: EControlPositionType,
  ) => void;
  openAutomations: (
    marketId: number,
    positionAddress: string,
  ) => void;
  openDeleveragePosition: (
    positionAddress: string,
    controlType?: EControlPositionType,
  ) => void;
  openAddLiquidityPosition: (
    positionAddress: string,
  ) => void;
  openClaimFeesPosition: (
    positionAddress: string,
  ) => void;
  openCompoundPosition: (
    positionAddress: string,
  ) => void
  openAutoCompoundFees: (
    positionAddress: string,
  ) => void;
  openAutoRebalance: (
    positionAddress: string,
  ) => void;
  openAutoExit: (
    positionAddress: string,
  ) => void;
  openClosePosition: (
    positionAddress: string,
    controlType?: EControlPositionType,
  ) => void;
  openBorrow: (
    underlyingAsset: string,
    currentMarket: string,
    name: string,
    funnel: string,
    isReserve?: boolean
  ) => void;
  openRepay: (
    underlyingAsset: string,
    isFrozen: boolean,
    currentMarket: string,
    name: string,
    funnel: string
  ) => void;
  openCollateralChange: (
    underlyingAsset: string,
    currentMarket: string,
    name: string,
    funnel: string,
    usageAsCollateralEnabledOnUser: boolean
  ) => void;
  openStake: (stakeAssetName: string, icon: string) => void;
  openUnstake: (stakeAssetName: string, icon: string) => void;
  openStakeCooldown: (stakeAssetName: string) => void;
  openStakeRewardsClaim: (stakeAssetName: string, icon: string) => void;
  openStakeRewardsRestakeClaim: (stakeAssetName: string, icon: string) => void;
  openClaimRewards: () => void;
  openFaucet: (underlyingAsset: string) => void;
  openSwap: (underlyingAsset: string) => void;
  openGovDelegation: () => void;
  openRevokeGovDelegation: () => void;
  openGovVote: (proposalId: number, support: boolean, power: string) => void;
  openSwitch: (underlyingAsset?: string) => void;
  close: () => void;
  type?: ModalType;
  args: T;
  mainTxState: TxStateType;
  approvalTxState: TxStateType;
  setApprovalTxState: (data: TxStateType) => void;
  setMainTxState: (data: TxStateType) => void;
  gasLimit: string;
  setGasLimit: (limit: string) => void;
  loadingTxns: boolean;
  setLoadingTxns: (loading: boolean) => void;
  txError: TxErrorType | undefined;
  setTxError: (error: TxErrorType | undefined) => void;
}

export const ModalContext = createContext<ModalContextType<ModalArgsType>>(
  {} as ModalContextType<ModalArgsType>
);

export const ModalContextProvider: React.FC = ({ children }) => {
  const { setSwitchNetworkError } = useWeb3Context();
  // contains the current modal open state if any
  const [type, setType] = useState<ModalType>();
  // contains arbitrary key-value pairs as a modal context
  const [args, setArgs] = useState<ModalArgsType>({});
  const [approvalTxState, setApprovalTxState] = useState<TxStateType>({});
  const [mainTxState, setMainTxState] = useState<TxStateType>({});
  const [gasLimit, setGasLimit] = useState<string>('');
  const [loadingTxns, setLoadingTxns] = useState(false);
  const [txError, setTxError] = useState<TxErrorType>();
  const trackEvent = useRootStore((store) => store.trackEvent);

  return (
    <ModalContext.Provider
      value={{
        openSupply: (underlyingAsset) => {
          setType(ModalType.Supply);
          setArgs({ underlyingAsset });
        },
        openSupplyPosition: (tokenId) => {
          setType(ModalType.SupplyPosition);
          setArgs({ tokenId });
        },
        openWithdraw: (underlyingAsset) => {
          setType(ModalType.Withdraw);
          setArgs({ underlyingAsset });
        },
        openWithdrawPosition: (suppliedTokenId) => {
          setType(ModalType.WithdrawPosition);
          setArgs({ suppliedTokenId });
        },
        openLeveragePosition: (tokenId) => {
          setType(ModalType.LeveragePosition);
          setArgs({ tokenId });
        },
        openDeleveragePosition: (positionAddress, controlType) => {
          setType(ModalType.DeleveragePosition);
          setArgs({ positionAddress, controlType });
        },
        openAddLiquidityPosition: (positionAddress) => {
          setType(ModalType.AddLiquidity);
          setArgs({ positionAddress });
        },
        openClosePosition: (positionAddress, controlType) => {
          setType(ModalType.ClosePosition);
          setArgs({ positionAddress, controlType });
        },
        openCompoundPosition: (positionAddress) => {
          setType(ModalType.CompoundFees);
          setArgs({ positionAddress });
        },
        openClaimFeesPosition: (positionAddress) => {
          setType(ModalType.ClaimFees);
          setArgs({ positionAddress });
        },
        openAutoCompoundFees: (positionAddress) => {
          setType(ModalType.AutoCompoundFees);
          setArgs({ positionAddress });
        },
        openAutoRebalance: (positionAddress) => {
          setType(ModalType.AutoRebalance);
          setArgs({ positionAddress });
        },
        openAutoExit: (positionAddress) => {
          setType(ModalType.AutoExit);
          setArgs({ positionAddress });
        },
        openActions: (positionAddress, controlType) => {
          setType(ModalType.Actions);
          setArgs({ positionAddress, controlType });
        },
        openAutomations: (marketId, positionAddress) => {
          setType(ModalType.Automations);
          setArgs({ marketId, positionAddress });
        },
        openBorrow: (underlyingAsset, currentMarket, name, funnel, isReserve) => {
          setType(ModalType.Borrow);
          setArgs({ underlyingAsset });
          if (isReserve) {
            trackEvent(GENERAL.OPEN_MODAL, {
              modal: 'Borrow',
              market: currentMarket,
              assetName: name,
              asset: underlyingAsset,
              funnel,
            });
          } else {
            trackEvent(GENERAL.OPEN_MODAL, {
              modal: 'Borrow',
              market: currentMarket,
              assetName: name,
              asset: underlyingAsset,
              funnel,
            });
          }
        },
        createUniswapPosition: () => {
          setType(ModalType.CreatePosition);
        },
        rebalanceUniswapPosition: () => {
          setType(ModalType.Rebalance);
        },
        openRepay: (underlyingAsset, isFrozen) => {
          setType(ModalType.Repay);
          setArgs({ underlyingAsset, isFrozen });
        },
        openCollateralChange: (underlyingAsset) => {
          setType(ModalType.CollateralChange);
          setArgs({ underlyingAsset });
        },
        openStake: (stakeAssetName, icon) => {
          setType(ModalType.Stake);
          setArgs({ stakeAssetName, icon });
        },
        openUnstake: (stakeAssetName, icon) => {
          setType(ModalType.Unstake);
          setArgs({ stakeAssetName, icon });
        },
        openStakeCooldown: (stakeAssetName) => {
          setType(ModalType.StakeCooldown);
          setArgs({ stakeAssetName });
        },
        openStakeRewardsClaim: (stakeAssetName, icon) => {
          setType(ModalType.StakeRewardClaim);
          setArgs({ stakeAssetName, icon });
        },
        openStakeRewardsRestakeClaim: (stakeAssetName, icon) => {
          setType(ModalType.StakeRewardsClaimRestake);
          setArgs({ stakeAssetName, icon });
        },
        openClaimRewards: () => {
          setType(ModalType.ClaimRewards);
        },
        openFaucet: (underlyingAsset) => {
          setType(ModalType.Faucet);
          setArgs({ underlyingAsset });
        },
        openSwap: (underlyingAsset) => {
          setType(ModalType.Swap);
          setArgs({ underlyingAsset });
        },
        openGovDelegation: () => {
          setType(ModalType.GovDelegation);
        },
        openRevokeGovDelegation: () => {
          setType(ModalType.RevokeGovDelegation);
        },
        openGovVote: (proposalId, support, power) => {
          setType(ModalType.GovVote);
          setArgs({ proposalId, support, power });
        },
        openSwitch: (underlyingAsset) => {
          setType(ModalType.Switch);
          setArgs({ underlyingAsset });
        },
        close: () => {
          setType(undefined);
          setArgs({});
          setMainTxState({});
          setApprovalTxState({});
          setGasLimit('');
          setTxError(undefined);
          setSwitchNetworkError(undefined);
        },
        type,
        args,
        approvalTxState,
        mainTxState,
        setApprovalTxState,
        setMainTxState,
        gasLimit,
        setGasLimit,
        loadingTxns,
        setLoadingTxns,
        txError,
        setTxError,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }

  return context;
};
